import React from 'react'
import idx from 'idx'

import { diffInDays } from '../../../utils/diffInDays'
import Card from '../Card'

import './styles.css'

const CardGrid = ({ trips }) => (
  <div className="card-grid">
    {trips.map((trip, index) => {
      const { relationships, field_date, field_end_date } = trip
      const tripLength = diffInDays(field_date, field_end_date)
      const image = idx(relationships, _ => _.field_image[0])
      const name = idx(relationships, _ => _.uid.name)
      const countryCode = idx(trip, _ => _.field_address_1.country_code)

      return (
        !!image && (
          <Card
            countryCode={countryCode}
            key={index}
            tripLength={tripLength}
            trip={trip}
            image={image}
            name={name}
          />
        )
      )
    })}
  </div>
)

export default CardGrid
