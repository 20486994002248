import React from 'react'
import { Link } from 'gatsby'
import kebabCase from 'lodash.kebabcase'
import idx from 'idx'
import Country from '../Country'
import './styles.css'

const Card = ({
  trip,
  image,
  name,
  tripLength,
  countryCode,
  country,
  cost,
  tagline,
}) => {
  const { id, title: tripTitle, field_address_1, format_date } = trip
  const { locality } = idx(field_address_1, _ => ({ ..._ }))

  return (
    <div key={id} className="card">
      <Link
        className="card__wrapper"
        to={`/trip/${id}/${kebabCase(tripTitle)}`}
      >
        <div className="card__img-wrapper">
          {image && (
            <img
              className="card__img"
              src={`https://res.cloudinary.com/dvjhq5dpg/image/fetch/c_fill,c_fill,h_300,w_288/v1546964241/https://dev-skillsailors.pantheonsite.io${image.uri.url}`}
              alt="trip activity"
            />
          )}
        </div>
        <div className="card__info-container">
          <div className="card__info">
            <h2 className="card__name">
              {locality}, <Country countryCode={countryCode} />
            </h2>
          </div>
          <div className="card__date">
            <div>{format_date}</div>
            <div>{tripLength} days</div>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default Card
