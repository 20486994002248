import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import kebabCase from 'lodash.kebabcase'

import Layout from '../components/Layout'
import CardGrid from '../components/CardGrid'
import Masthead from '../components/Masthead'
import CloudinaryImage from '../components/CloudinaryImage'
import Button from '../components/Button'

import differenceInCalendarMonths from 'date-fns/differenceInCalendarMonths'
import isAfter from 'date-fns/isAfter'

import '../components/MastheadGrid/styles.css'
import Seo from '../components/Seo'
import Metatags from '../components/Metatags'

const tripFilter = trip => Date.now() <= new Date(trip.field_end_date).getTime()
const tripPastFilter = trip => Date.now() >= new Date(trip.field_end_date).getTime()

const getVoyageProgress = trips => {
  let tripsCompleted = 0
  const dateFormatOptions = {
    day: 'numeric',
    year: 'numeric',
    month: 'short',
  }

  trips.forEach(trip => {
    const tripEndDate = new Date(trip.field_end_date).getTime()
    if (Date.now() >= tripEndDate) tripsCompleted += 1
  })

  return {
    tripsCompleted,
    tripsLeft: trips.length - tripsCompleted,
    voyageStarts: new Date(trips[0].field_date).toLocaleDateString(
      'en-US',
      dateFormatOptions
    ),
  }
}



const IndexPage = ({ data }) => {
  return (
    <Layout>
      <Seo
        meta={[
          { name: 'description', content: '...' },
          { name: 'keywords', content: '....' },
        ]}
        links={[
          { rel: 'shortcut icon', type: 'image/svg', href: '/sailboat.svg' },
        ]}
      />
      <Metatags
        title="Skill Sailor"
        description={data.site.siteMetadata.description}
        canonical="https://www.skillsailors.com/"
      />

      <Masthead
        image="/nosara.jpg"
        heading="Start your CoWorking adventure"
        subheading="Work and play with like minded people."
      />

      <div className="layout-wrapper">
        {data.allNodeVoyage.edges
          .filter(voyage => voyage.node.relationships.field_voyage_trips)
          .map(voyage => {
            const voyageProgress = getVoyageProgress(
              voyage.node.relationships.field_voyage_trips
            )

            const sortDates = voyage.node.relationships.field_voyage_trips.sort(
              (a, b) => new Date(a.field_date) - new Date(b.field_date)
            )

            const firstTrip = new Date(sortDates[0].field_date)
            const lastTrip = new Date(
              sortDates[
                voyage.node.relationships.field_voyage_trips.length - 1
              ].field_end_date
            )

            const today = new Date()

            const diffMoth = differenceInCalendarMonths(
              lastTrip,
              isAfter(today, firstTrip) ? today : firstTrip
            )

            return (
              <div className="mastheadGrid">
                <div className="mastheadGrid__top-wrapper">
                  <div className="mastheadGrid__top">
                    <div className="mastheadGrid__info">
                      <Link
                        to={
                          '/user/' +
                          voyage.node.relationships.uid.drupal_internal__uid
                        }
                      >
                        {voyage.node.relationships.uid.relationships
                          .field_user_profile.relationships
                          .field_user_profile_image && (
                          <CloudinaryImage
                            class="mastheadGrid__author-img"
                            height="100"
                            width="100"
                            src={
                              voyage.node.relationships.uid.relationships
                                .field_user_profile.relationships
                                .field_user_profile_image.uri.url
                            }
                          />
                        )}
                      </Link>
                      <div>
                        <h2 className="mastheadGrid__title">
                          {voyage.node.title}
                        </h2>
                        <Link
                          to={
                            '/user/' +
                            voyage.node.relationships.uid.drupal_internal__uid
                          }
                        >
                          <div className="mastheadGrid__captain">
                            {voyage.node.relationships.uid.name}
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="mastheadGrid__data-wrapper">
                    <div className="mastheadGrid__btn">
                      <Button
                        link={`voyage/${
                          voyage.node.drupal_internal__nid
                        }/${kebabCase(voyage.node.title)}`}
                        text="Learn More"
                      />
                    </div>
                    <div className="mastheadGrid__data">
                      <div className="mastheadGrid__trips-left">
                        {voyageProgress.tripsCompleted === 0
                          ? `Starts ${voyageProgress.voyageStarts}`
                          : `${voyageProgress.tripsCompleted} ${
                              voyageProgress.tripsCompleted === 1
                                ? ' trip completed'
                                : ' trips completed'
                            }`}
                      </div>
                      <div className="mastheadGrid__days">
                        {diffMoth} {diffMoth > 1 ? 'months' : 'month'}{' '}
                        {isAfter(today, firstTrip) ? 'left' : ''}
                      </div>
                      <div className="mastheadGrid__price">
                        ${voyage.node.field_monthly_cost} per month
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="mastheadGrid__label">Upcoming Trips</div>
                  <CardGrid trips={sortDates.filter(tripFilter)} />
                </div>
                {
                  sortDates.filter(tripPastFilter).length > 0 &&
                  <div>
                    <div className="mastheadGrid__label">Past Trips</div>
                    <CardGrid trips={sortDates.filter(tripPastFilter)} />
                  </div>
                }
              </div>
            )
          })}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allNodeVoyage(sort: { order: ASC, fields: field_start_date }) {
      edges {
        node {
          drupal_id
          id
          field_total_days
          field_monthly_cost
          title
          field_amenities
          field_start_date
          drupal_internal__nid
          title
          body {
            value
          }
          relationships {
            uid {
              name
              drupal_internal__uid
              relationships {
                field_user_profile {
                  relationships {
                    field_user_profile_image {
                      uri {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
          relationships {
            field_voyage_trips {
              id: drupal_internal__nid
              title
              field_tag_line
              field_date
              field_end_date
              format_date: field_date(formatString: "MMM YYYY")
              field_address_1 {
                administrative_area
                country_code
                locality
              }
              relationships {
                field_accepted_users {
                  name
                }
                field_image {
                  uri {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
